import logo from './logo-ovvio.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <body className="App-body">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="App-description">
          “OVVIO” WE WILL MAKE YOUR IDEA HAPPEN
        </p>
      </body>
      <footer color="App-footer">
        <ul className="App-footer-links">
          <li className="App-footer-link"><a className="App-link" href="#">ABOUT ME</a></li>
          <li className="App-footer-link"><a className="App-link" href="#">PRIVACY POLICY</a></li>
          <li className="App-footer-link"><a className="App-link" href="#">USE CONDITIONS</a></li>
        </ul>
      </footer>
    </div>
  );
}

export default App;
